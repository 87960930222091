<template>
    <div class="animated fadeIn">
        <LoadingWidget v-if="initStatus != 1" :status="initStatus" @init="init" />
        <!-- v-else -->
        <b-card v-else>
            <template v-slot:header>
                <h5>Merk Barang</h5>
            </template>
            <div class="search-wrapper d-flex align-items-center justify-content-between">
                <b-form-group label="Filter" label-for="filterInput" class="form-inline form-group-wrapper mb-3">
                    <div class="d-flex search-input-wrapper">
                        <b-form-input class="ml-2 mr-2" v-model="filter.merk" name="filterTable" id="filterInput"
                            @keyup="getMerk()" type="search" placeholder="Cari Merk"></b-form-input>
                        <b-form-select class="ml-2 mr-2" id="filterInput" v-model="filter.golongan" name="filterJenis"  @change="getMerk">
                            <template v-slot:first>
                                <b-form-select-option :value="null" disabled>-- Pilih jenis --</b-form-select-option>
                            </template>
                            <b-form-select-option v-for="jenis in jenisBarang" :value="jenis.id">
                                {{ jenis.text }}
                            </b-form-select-option>
                        </b-form-select>
                        <!-- <b-button class="mr-2" variant="pgiBtn" name="searchTableBtn" :disabled="isBusy"
                            @click="onFilter">
                            <i class="fa fa-search"></i>
                        </b-button> -->
                        <b-button variant="pgiBtn" name="resetTableBtn" :disabled="isBusy"
                            @click="onReset()">Reset</b-button>
                    </div>
                </b-form-group>
                <div>
                    <b-button class="mb-3 mr-2" variant="pgiBtn" name="kategoriAddBtn"
                        @click="$bvModal.show('m-create')">Input Merk Barang
                    </b-button>
                </div>
            </div>
            <b-table bordered hover show-empty :busy="isBusy" :items="merkBarang" :fields="fields" :perPage="perPage"
                responsive class="mb-3">
                <template v-slot:table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong> Loading data...</strong>
                    </div>
                </template>
                <template v-slot:empty>
                    Tidak ada data jenis barang.
                </template>
                <template v-slot:cell(action)="row">
                    <div class="no-wrap">
                        <b-button size="sm" class="mr-3 table-button edit" v-b-tooltip.hover title="Edit"
                            name="kategoriEditBtn"
                            @click="showModalEdit(`${row.item.id}`, `${row.item.merkBarang}`, `${row.item.idJenis}`)">
                            <i class="fa fa-pencil-square-o"></i>
                        </b-button>

                        <b-button size="sm" class="mr-3 table-button edit" v-b-tooltip.hover title="Delete"
                            name="jenisBarangHapusBtn" @click="showModalHapus(`${row.item.id}`)">
                            <i class="fa fa-trash text-danger"></i>
                        </b-button>
                    </div>
                </template>
            </b-table>

            <div class="pagination-wrapper d-flex align-items-center justify-content-between">
                <span class="ml-2">{{ totalViewText }}</span>
                <b-pagination class="mb-0" v-model="currentPage" :total-rows="rows" :per-page="perPage"
                    aria-controls="my-table" @input="onPageChange"></b-pagination>
            </div>
        </b-card>


        <!-- MODAL CREATE -->
        <b-modal id="m-create" class="modal-lg" title="Input Merk Barang" no-close-on-backdrop no-close-on-esc
            hide-footer>

            <b-form-group id="input-merk" label="Merk Barang" label-for="input-merk">
                <b-form-input id="input-merk" v-model="formInput.merk" type="text" required></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-3" label="Jenis Barang" label-for="jenis barang">
                <b-form-select id="jenis barang" v-model="formInput.id_jenis" required>
                    <template v-slot:first>
                        <b-form-select-option :value="null" disabled>-- Pilih Jenis Barang --</b-form-select-option>
                    </template>
                    <b-form-select-option v-for="jenis in jenisBarang" :value="jenis.id">
                        {{ jenis.text }}
                    </b-form-select-option>
                </b-form-select>
            </b-form-group>

            <div class="mt-4 text-right">
                <b-button variant="pgiBtn" @click="prepareInput('m-confirm-create')">
                    Simpan
                </b-button>
            </div>
        </b-modal>

        <ModalConfirm id="m-confirm-create" title="Confirm Tambah Merk Barang" :isSubmitting="isSubmitting"
            desc="Apakah anda yakin untuk menambah Merk Barang ini ?" @submit="submitInput" />

        <!-- MODAL EDIT -->
        <b-modal id="m-edit" class="modal-lg" title="Edit Merk" no-close-on-backdrop no-close-on-esc hide-footer>

            <b-form-group id="input-jenis" label="Merk Barang" label-for="input-jenis">
                <b-form-input id="input-jenis" v-model="form.merk" type="text" required></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-3" label="Jenis Barang" label-for="jenis barang">
                <b-form-select id="jenis barang" v-model="form.id_jenis" required>
                    <template v-slot:first>
                        <b-form-select-option :value="null" disabled>-- Pilih Jenis Barang --</b-form-select-option>
                    </template>
                    <b-form-select-option v-for="jenis in jenisBarang" :value="jenis.id">
                        {{ jenis.text }}
                    </b-form-select-option>
                </b-form-select>
            </b-form-group>

            <div class="mt-4 text-right">
                <b-button variant="pgiBtn" @click="prepare('m-confirm-edit')">
                    Simpan
                </b-button>
            </div>
        </b-modal>

        <ModalConfirm id="m-confirm-edit" title="Confirm Tambah Merk Barang" :isSubmitting="isSubmitting"
            desc="Apakah anda yakin untuk mengubah Merk Barang ini ?" @submit="submitEdit" />

        <ModalConfirm id="m-confirm-hapus" title="Confirm Hapus Merk barang" :isSubmitting="isSubmitting"
            desc="Apakah anda yakin untuk menghapus Merk barang ini ?" @submit="hapus" />
    </div>
</template>

<script>
export default {
    name: 'master-merk-barang',
    data() {
        return {
            initStatus: 0,
            isBusy: true,
            isSubmitting: false,
            activeId: null,
            input: {
                show: 10
            },

            filter: {
                merk: null,
                golongan: null,
            },

            form: {
                merk: '',
                id_jenis: null,
            },

            formInput: {
                merk: '',
                id_jenis: null,
            },

            jenisBarang: [],

            fields: [
                { key: "merkBarang", label: "Merk Barang", },
                { key: "jenisBarang", label: "Jenis Barang", },
                { key: "kategori", label: "Kategori", },
                { key: "action", label: "Aksi" },
            ],
            options: [
                { value: 10, text: '10' },
                { value: 20, text: '20' },
                { value: 30, text: '30' },
                { value: 40, text: '40' },
                { value: 50, text: '50' },
            ],
            merkBarangAll: [],
            merkBarang: [],
            currentPage: 1,
            perPage: null,
            rows: null,
            totalViewText: "",
            page: '',
            size: '',
            from: '',
            limit: '',
            loading: false,
            query: "",

            //****SEMENTARA barang jenis null 
            null: [{
                id: null,
                jenis: "null",
                golongan: "null"
            }],
        }
    },

    methods: {

        //----------------- Fungsi Inisialisasi -----------------

        init() {
            this.initStatus = 0
            this.page = 1
            this.query = "?page=" + this.page

            let getJenis = this.$axios.get("api/admin/barang-jenis/all").then(response => {
                // console.log(response.data.data);
                let datas = response.data.data
                for (var data of datas) {
                    this.jenisBarang.push({ id: data.id, text: data.jenis })
                }
            })

            let getMerk = this.$axios.get("api/admin/barang-merk").then(response => {
                let datas = response.data.data
                console.log(datas);
                for (var data of datas.data) {

                    //****SEMENTARA barang jenis null 
                    if (data.barang_jenis[0] == undefined) {
                        this.merkBarang.push({
                            id: data.id,
                            merkBarang: data.merk,
                            idJenis: this.null[0].id,
                            jenisBarang: this.null[0].jenis,
                            kategori: this.null[0].golongan
                        })
                    }

                    else {
                        this.merkBarang.push({
                            id: data.id,
                            merkBarang: data.merk,
                            idJenis: data.barang_jenis[0].id,
                            jenisBarang: data.barang_jenis[0].jenis,
                            kategori: data.barang_jenis[0].golongan
                        })
                    }

                    //console.log(this.merkBarang)
                }

                this.perPage = datas.per_page
                this.size = this.perPage
                this.from = datas.from
                this.limit = datas.to

                this.updateTotalItem(datas.total)
                let msg = this.$helper.getFlash()
                this.$helper.toastSucc(this, msg)

            })

            Promise.all([getMerk, getJenis]).then(() => {
                this.initStatus = 1
                this.toggleBusy()
            })
                .catch(error => {
                    console.log(error)
                    this.initStatus = -1
                    this.toggleBusy()
                })
        },
        getMerk() {

            this.toggleBusy()
            this.merkBarang = []

            if (this.filter.merk != null) this.query = this.query + "&merk=" + this.filter.merk
            if (this.filter.golongan != null) this.query = this.query + "&id_jenis=" + this.filter.golongan

            console.log(this.query);

            this.$axios.get("api/admin/barang-merk" + this.query).then(response => {
                let datas = response.data.data

                //this.updateTotalItem(datas.total)
                for (var data of datas.data) {

                    //****SEMENTARA barang jenis null 
                    if (data.barang_jenis[0] == undefined) {
                        this.merkBarang.push({
                            id: data.id,
                            merkBarang: data.merk,
                            idJenis: this.null[0].id,
                            jenisBarang: this.null[0].jenis,
                            kategori: this.null[0].golongan
                        })
                    }


                    else {
                        this.merkBarang.push({
                            id: data.id,
                            merkBarang: data.merk,
                            idJenis: data.barang_jenis[0].id,
                            jenisBarang: data.barang_jenis[0].jenis,
                            kategori: data.barang_jenis[0].golongan
                        })
                    }

                    //console.log(this.merkBarang)
                }

                this.perPage = datas.per_page
                this.size = this.perPage
                this.from = datas.from
                this.limit = datas.to

                this.toggleBusy()
                this.updateTotalItem(datas.total)
                this.loading = false

            })
                .catch(error => {
                    console.log(error)
                    console.log(error.response.data.reason)
                    this.$helper.parseError(this, error)
                    this.toggleBusy()
                    this.loading = false
                })
        },

        //-----------------  Fungsi Input ----------------- 
        prepareInput(modalToShow) {

            if (this.formInput.merk == "") {
                return this.$helper.toastErr(this, "Mohon mengisi Merk Barang!")
            }
            console.log(this.formInput.id_jenis);
            if (this.formInput.id_jenis == null) {
                return this.$helper.toastErr(this, "Mohon mengisi Jenis Barang!")
            }

            this.$bvModal.show(modalToShow)
        },

        submitInput() {


            this.isSubmitting = true
            this.$axios.post("/api/admin/barang-merk", this.formInput).then(res => {

                this.$helper.toastSucc(this, "Merk Barang Berhasil Ditambahkan")
                this.$bvModal.hide("m-confirm-create")
                this.$bvModal.hide("m-create")

                this.formInput = {
                    merk: '',
                    id_jenis: null,
                }
                this.getMerk()

            })
                .catch(error => this.$helper.parseError(this, error))
                .finally(() => this.isSubmitting = false)

        },

        //-----------------  Fungsi Edit ----------------- 

        prepare(modalToShow) {

            if (this.form.merk == "") {
                return this.$helper.toastErr(this, "Mohon mengisi Merk Barang!")
            }
            console.log(this.form.id_jenis);
            if (this.form.id_jenis == null) {
                return this.$helper.toastErr(this, "Mohon mengisi Jenis Barang!")
            }

            this.$bvModal.show(modalToShow)
        },

        showModalEdit(id, merk, jenis) {
            console.log(id);
            console.log(merk);
            console.log(jenis);
            this.activeId = id
            this.form.merk = merk
            this.form.id_jenis = (jenis != "null") ? jenis : null

            this.$bvModal.show('m-edit')
        },


        submitEdit() {

            console.log(this.form);
            this.isSubmitting = true
            this.$axios.post("/api/admin/barang-merk/" + this.activeId, this.form).then(res => {

                this.$helper.toastSucc(this, "Merk Berhasil Diedit")
                this.$bvModal.hide("m-confirm-edit")
                this.$bvModal.hide("m-edit")

                this.form = {
                    merk: '',
                    id_jenis: null,
                }
                this.getMerk()

            })
                .catch(error => this.$helper.parseError(this, error))
                .finally(() => this.isSubmitting = false)

        },


        //-----------------  Fungsi Hapus ----------------- 
        showModalHapus(id) {
            this.activeId = id
            this.$bvModal.show('m-confirm-hapus')
        },

        hapus() {
            this.$axios.delete("api/admin/barang-merk/delete/" + this.activeId).then(response => {

                this.$helper.toastSucc(this, "Merk Barang Berhasil Dihapus")
                this.$bvModal.hide("m-confirm-hapus")

                this.form = {
                    merk: '',
                    id_jenis: null,
                }
                this.getMerk()
            })
        },

        //----------------- Fungsi Pagination, Sorting, dan Filtering -----------------

        onFilter() {
            this.page = 1
            this.currentPage = 1
            this.query = "?page=" + this.page
            this.loading = true
            this.getMerk()
        },

        onPageChange() {
            console.log("ke on page");
            if (this.loading == false) {
                console.log(this.currentPage);
                this.page = this.currentPage
                this.query = "?page=" + this.page

                this.getMerk()
            }
            console.log(this.query);
        },

        onReset() {
            this.filter = {
                jenis: null,
                golongan: null,
            },

            this.page = 1
            this.query = "?page=" + this.page
            this.currentPage = 1
            this.loading = true
            this.getMerk()
        },


        //----------------- Fungsi Pindah Halaman dan Fungsi Lain -----------------

        toggleBusy() {
            if (this.isBusy == true) this.isBusy = false
            else this.isBusy = true
        },

        updateTotalItem(total) {
            this.perPage = this.size
            this.rows = total
            if (this.limit > this.rows) limit = this.rows
            this.totalViewText = "Menampilkan " + this.from + " - " + this.limit + " dari " + this.rows + " entri"
        },

    },

    created() {
        this.init()
    }
}
</script>
